body{
  background-color: rgb(6, 0, 26);
  justify-content: center;
}

a{
  color: white;
  text-decoration: none;
}

.icon-style{
  margin :5px;
}



.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: center;
  
  color: white;
}

.previous_challenges_div{
  margin-top: 25px;
}

.previous_challenges{
  max-width: 400px;
  padding: 0.5rem;
  font-size: 1rem;
}

.buttons{
  background-color: #5602b6;
  border-radius: 0.5rem;
  border: 1px solid #5602b6;
  color: white;
  margin: auto;
  font-size: 20px;
  
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor : pointer;
}

.generate{
  width: 120px;
  height: 50px;
}




.container-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  width: 70%;
  margin: auto;
}
.id-list{
  width: 40px;

}

.topic-list{
  padding: 20px;
}

.left-of-table{
  float: left;
  flex-basis:300px;
  text-align: center;
  display: flex;
  justify-content: space-between;

}
.date-list{
  display: inline;
  flex-basis:100px;
}
.app-list{
  display: inline;
  flex-basis:200px;
}

.color0-list{
  background-color: #5602b699;
}

.color1-list{
  background-color: #5602b655;
}

.container-buttons-criterias{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 950px;
  margin: auto;
}


.gradient-style{
  background: linear-gradient(130deg,  #1500ff, #00ff86);
}


.gradient-criterias{
  background: linear-gradient(130deg,  blue, pink);
}



.gradient-details{
  background: linear-gradient(130deg,  blue, #4ac8d0);
}


.buttons-criterias-border{
  border-radius: 0.5rem;
  height: 100vh;
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  height : 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  margin : 20px;
}
.buttons-criterias{
  border-radius: 0.5rem;
  background-color: #1a222c;
  width: 142px;
  height : 102px;
  position: relative;
  transition: background-color 0.2s;
  cursor : pointer;
  user-select: none;
}


@media(hover: hover) and (pointer: fine) {
  .buttons-criterias:hover{
    background-color:rgb(87, 87, 87, 0);
  }
  .gradient-details:hover{
    box-shadow: 0 0 20px #0a59ff; 
  }
  .gradient-criterias:hover{
    box-shadow: 0 0 20px #972dff; 
  }
  .gradient-style:hover{
    box-shadow: 0 0 20px #1c9b99; 
  }
  .buttons:hover{
    box-shadow: 0 0 20px #5602b6; 
  }
  .icon-style:hover{
    color:#5602b6;
  }
  .page-item:hover{
    background-color: #8357b6;
    box-shadow: 0 0 40px #8357b6; 
  }
}

.buttons-criterias-selected{
  background-color:rgb(87, 87, 87, 0);
  border-radius: 0.5rem;
  width: 142px;
  height : 102px;
  position: relative;
  transition: background-color 0.2s;
  cursor : pointer;
  user-select: none;
}

.shadow-criterias-selected{
  box-shadow: 0 0 20px #972dff; 
}
.shadow-details-selected{
  box-shadow: 0 0 20px #0a59ff; 
}
.shadow-style-selected{
  box-shadow: 0 0 20px #1c9b99; 
}

.criteria{
  color: white;
  position: absolute;
  top: 0px;
  left: 10px;
  text-align: left;
  width : 122px;
}
.criteria-example{
  color: rgb(87, 87, 87);
  position: absolute;
  bottom: 0px;
  right: 5px;
  font-size: 0.7em;
  text-align: right;
  width: 85px;
}


/*

POUR LES TELEPHONES

*/

.section{
  width:80%;
  margin: auto;
  padding-bottom: 20px;
}
.display-none{
  display: none;
}

.app-list-title{
  flex-basis:200px;
}
.hr-list{
  width: 70%;
  margin: auto;
}

@media screen and (max-width: 1200px){
  .hr-list{
    width: 90%;
  }
  .container-list{
    font-size: 1.25em;
    width: 90%;
  }

}

@media  screen and (max-width: 800px) {
  .hr-list{
    width: 100%;
  }
  .left-of-table{
    flex-basis:120px;
    text-align: center;
    justify-content: space-between;
    display: inline-block;
  
  }
  .display-none{
    display: inline;
  }
  .app-list{
    display: block;
    flex-basis:120px;
  }
  .app-list-title{
    display: inline;
  }

  .container-list{
    font-size: 1em;
    width: 100%;
  }

  .topic-list{
    padding: 20px;
  }
  
}

@media screen and (max-width: 800px) {
  .buttons-criterias-border{
    border-radius: 0.5rem;
    height: 100vh;
    max-width: 90px;
    min-width: 90px;
    height : 50px;
    margin : 5px

  }
  .buttons-criterias{
    width: 82px;
    height : 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .buttons-criterias-selected{
    width: 92px;
    height : 42px;
    display: flex;

  }
  .criteria{
    font-size: 0.7em;
    width : 82px;
    text-align: center;
    left: 0px;
    margin: auto;
    position: static;

    

  }
  .criteria-example{
    display: none;
  }
  .container-buttons-criterias{
    max-width: 520px;
  }
  .section{
    width: 100%;
  }
}

hr{
  width : 55%;
  margin-top: 70px;
  margin-bottom: 100px;
}

.copiright-footer{
  margin-bottom: 100px;
}

.topic-idea{
  color:#7c14f2;
  font-size: 2.5em;
}
.topic-idea-container{
  max-width: 800px;
  margin: auto;
}

.title-drawfinity{
  max-width: 400px;
}
@media screen and (max-width: 400px) {
  .title-drawfinity{
    width:100%;
  }
}


@media screen and (max-width: 650px) {
  .topic-idea{
    font-size: 2em;
  }
}
@media screen and (max-width: 500px) {
  .topic-idea{
    font-size: 1.5em;
  }
}

.pagination{
  list-style: none;
  padding: 0;
  display: flex;
  font-size: 1.2em;
  justify-content: center;
  margin-top: 50px;
}
.page-item{
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 25%;
  margin: 5px;
  vertical-align: middle;
  line-height: 38px;

  
}
.prev-next{
  line-height: 35px;
}
.page-link{
  background-color: #5602b6;;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  user-select: none;
}
.active-page{
  font-weight: bold;
}
.active-page-link{
  background-color: #360074;
}



.nav-list{
  align-items: center;
}

h1 {
  font-size: 65px;
  background: -webkit-linear-gradient(-45deg, rgb(217, 26, 255), rgb(32, 13, 207));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h1-div-size {
  width: 350px;
  margin: auto;
  position: relative;
}

.arrow{
  font-weight: bolder;
}

#generate-return{

}

.generate-return-div{
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
  width: 120px;
  height: 60px;
}

.generate2{
  width: 120px;
  height: 60px;
}